import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Button from '../../../ui/button';

import { Image } from '../../../ui/image';

const SectionDemand: FC<{ translation: TFunction; localKey: string }> = ({
  translation,
  localKey,
}) => {
  const { t } = useTranslation(localKey);
  const cards: any = t('demand.cards', {
    returnObjects: true,
  });

  return (
    <section className="section section-earn">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large text-black has-text-centered">
              {translation('demand.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-centered">
          {Object.keys(cards).map((key) => {
            const { title, text } = cards[key];
            return (
              <div key={key} className="column is-12-mobile  has-text-centered">
                <div className="demand_card">
                  <Image
                    name="tick-black.svg"
                    alt={key}
                    className="column-icon"
                  />
                  <h4 className="text-black">
                    <b>{title}</b>
                  </h4>
                  <p className="has-text-centered text-medium">{text}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="columns is-centered">
          <div className="column has-text-centered buttons-container">
            <Button to={translation('demand.ctaUrl')}>
              {translation('demand.ctaText')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionDemand };
