import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionHero } from '../components/partials/partner/section-hero';
import { SectionRecommendation } from '../components/partials/partner/section-suggestion';
import { SectionPartners } from '../components/partials/partner/section-partners';
import { SectionEarn } from '../components/partials/partner/section-earn';
import { SectionDemand } from '../components/partials/partner/section-demand';
import { SectionCommunity } from '../components/partials/partner/section-community';
import { ReferralSection } from '../components/sections/referral-section';
import SectionColumns from '../components/sections/columns-section';

const ReferralPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { lang, alternateUrls } = pageContext;
  const { t } = useTranslation(localKey);

  const recommendedPrograms: IColumnList = t('recommendedPrograms.columns', {
    returnObjects: true,
  });

  let demand = false;
  const demandCards: any = t('demand.cards', {
    returnObjects: true,
  });
  if (typeof demandCards === 'object') demand = true;

  let earn = false;
  const earnCards: any = t('earnAsPratner.cards', {
    returnObjects: true,
  });
  if (typeof earnCards === 'object') earn = true;

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <SectionHero translation={t} />
      <SectionRecommendation translation={t} />
      <SectionColumns
        classes=""
        title={t('recommendedPrograms.title')}
        columns={recommendedPrograms}
      />

      <SectionPartners localKey={localKey} translation={t} />
      {earn && <SectionEarn localKey={localKey} translation={t} />}
      {demand && <SectionDemand localKey={localKey} translation={t} />}
      <SectionCommunity translation={t} />
      <ReferralSection translation={t} />
    </Layout>
  );
};

export default ReferralPage;
