import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ImageGallery } from '../../../ui/imageGallery';
import { IPartnerList } from '../../../../utils/types';

const SectionPartners: FC<{ translation: TFunction; localKey: string }> = ({
  translation,
  localKey,
}) => {
  const { t } = useTranslation(localKey);
  const list: IPartnerList = t('partnership.list', {
    returnObjects: true,
  });

  return (
    <section className="section section-partners">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2
              className="title title-large text-black has-text-centered"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translation('partnership.title'),
              }}
            />
          </div>
        </div>
        <div className="columns is-centered">
          {Object.keys(list).map((key) => {
            const { icon, text, title, alt } = list[key];
            return (
              <div key={key} className="column is-3 has-text-centered">
                <ImageGallery name={icon} alt={alt} className="partner-icon" />
                <h3 className="title title-small">{title}</h3>
                <p className="partner-text has-text-centered text-medium">
                  {text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SectionPartners };
