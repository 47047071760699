import * as React from 'react';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ColSlider } from '../partials/common/elements/Slider';
import { Image } from '../ui/image';

const SectionColumns: FC<{
  title?: string;
  columns: any;
  classes?: string;
}> = ({ title, columns, classes }) => {
  return (
    <section className={`${classes} section section-columns`}>
      <div className="container">
        {title && (
          <div className="columns is-centered">
            <div className="column is-8 is-12-mobile is-12-tablet">
              <h2
                className="title title-large has-text-centered text-black"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </div>
        )}
        <div className="columns is-centered">
          {Object.keys(columns).length <= 3 || isMobileOnly ? (
            Object.keys(columns).map((key) => {
              const { text, alt } = columns[key];
              return (
                <div
                  key={key}
                  className="column is-4 is-12-mobile  is-4-tablet has-text-centered"
                >
                  <Image name={key} alt={alt || ''} className="column-icon" />
                  <p className="has-text-centered text-medium">{text}</p>
                </div>
              );
            })
          ) : (
            <ColSlider slides={columns} />
          )}
        </div>
      </div>
    </section>
  );
};

SectionColumns.defaultProps = {
  classes: ` default `,
  title: null,
};
export default SectionColumns;
